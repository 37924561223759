import React from 'react';
import AddEntryButton from '../AddEntryButton';
import CollectionList from '../CollectionList';

const AlbumsTracksCollection = ({sdk, type}) => {
  const [list, setList] = React.useState([{}]);

  /* Load saved information */
  React.useEffect(() => {
    console.log(sdk.field.getValue())
    if (sdk.field.getValue()) {
      setList(sdk.field.getValue() );
    }
  }, [sdk])

  /* Save changed information */
  React.useEffect(() => {
    sdk.field.setValue(list);
  }, [list, sdk])

  /* Callback Handlers */
  const handleAddition = () => {
    setList((prevState) => {
      return [...prevState, {}]
    });
  };

  const handleItemChange = (index, data) => {
    setList(prevState => {
      prevState[index] = data
      return [...prevState];
    });
  };

  const handleItemDelete = (index) => {
    setList(prevState => {
      if (prevState.length === 1) {
        return [{}];
      }

      return prevState.filter((item, i) => {
        return index !== i;
      })
    })
  }

  return (
    <div role="region">
      <CollectionList type={type} data={list} onItemChange={handleItemChange} onItemDelete={handleItemDelete} />
      <AddEntryButton type={type} onClick={handleAddition}/>
    </div>
  )
};

export default AlbumsTracksCollection;
