import React from 'react';
import {Button, Flex, SectionHeading, TextInput } from '@contentful/forma-36-react-components';
import { wordMap } from '../../constants'

const FormItem = ({ type, item, onChange, onDelete }) => {
  return (
    <Flex margin="spacingXs" flexDirection="row" justifyContent="flex-start" htmlTag="li">
      <Flex flexDirection="column" fullWidth marginRight="spacingXs">
        <SectionHeading>{wordMap[type].identifier}</SectionHeading>
        <TextInput onChange={onChange} value={item.id} />
      </Flex>
      <Flex marginTop="spacingM">
        <Button buttonType="negative" icon="Delete" aria-label="Delete" onClick={onDelete}/>
      </Flex>
    </Flex>
  )
}

export default FormItem;
