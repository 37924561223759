import React from 'react';
import FormItem from '../FormItem';
import {Flex} from '@contentful/forma-36-react-components';

const CollectionList = ({ type, data, onItemChange, onItemDelete }) => {
  const handleItemChange = React.useCallback((e, index, item) => {
    onItemChange(index, {...item, id: e.target.value})
  }, [onItemChange]);

  const handleItemDelete = React.useCallback((index) => {
    onItemDelete(index);
  }, [onItemDelete]);

  const renderFormItem = (type, item, index) => (
    <FormItem
      type={type}
      item={item}
      key={`${type}-${index}`}
      onChange={(e) => handleItemChange(e, index, item)}
      onDelete={(e) => handleItemDelete(index)}
    />
  );

  return (
    <Flex htmlTag="ul" flexDirection="column" padding="none">
      {data.length && data.map((item, index) => renderFormItem(type, item, index))}
    </Flex>
  )
}

export default CollectionList;
