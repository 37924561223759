import React from 'react';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import AlbumsTracksCollection from './AlbumsTracksCollection';

interface FieldProps {
  sdk: FieldExtensionSDK;
}

const Field = (props: FieldProps) => {
  // If you only want to extend Contentful's default editing experience
  // reuse Contentful's editor components
  // -> https://www.contentful.com/developers/docs/extensibility/field-editors/
  //
  React.useEffect(() => {
    props.sdk.window.startAutoResizer();
  });

  const { id } = props.sdk.field;
  return <AlbumsTracksCollection type={id} sdk={props.sdk} />;
};

export default Field;
