import React from 'react';
import {  Button } from '@contentful/forma-36-react-components';
import {wordMap} from '../../constants'

const AddEntryButton = ({ type, onClick }) => (
    <Button onClick={onClick}>
        Add another {wordMap[type].label}
    </Button>
)

export default AddEntryButton;
