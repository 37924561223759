export const wordMap = {
  albums: {
    label: 'Album',
    identifier: 'Album code'
  },
  tracks: {
    label: 'Track',
    identifier: 'Track ID'
  }
}
