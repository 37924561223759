import React from 'react';
import {Flex, TextLink} from '@contentful/forma-36-react-components';

const Pagination = ({ total, current, onClick }) => {
  const pages = [];

  for (let i = 1; i <= total; i++) {
    const isCurrent = current === i
    pages.push(
        <TextLink
          key={`pagination-${i}`}
          disabled={current === i}
          linkType={ isCurrent ? "muted" : "primary" }
          className="page-link"
          href="#"
          onClick={(e) => {
            e.preventDefault()
            onClick(i)
          }}
          style={{ marginRight: 8, fontSize: 16 }}
        >
          {i}
        </TextLink>
    );
  }

  return (
    <div className="component__pagination" role="region">
      <Flex flexDirection="row" justifyContent="flex-start" margin="spacingM" fullWidth>
        {pages}
      </Flex>
    </div>
  ); 
}

Pagination.defaultProps = {
  onClick: () => {}
};
export default Pagination;
