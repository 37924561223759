import React from 'react';
import { Checkbox, DateTime, Table, TableBody, TableHead, TableRow, TableCell, TextLink } from '@contentful/forma-36-react-components';

const tableColumns = ['', 'Creator', 'Playlist title', 'Date created', 'Date Modified', 'Operations'];

const PlaylistTable = ({ contents, onItemSelected, onItemEdit }) => {
    const handleCheckboxChange = (e) => {
        onItemSelected()
    }

    const handleEditClick = (playlist) => {
       onItemEdit(playlist) 
    }

    return (
        <Table>
            <TableHead>
              <TableRow>
                {tableColumns.map(column => <TableCell key={column}>{column}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
                {contents.map(playlist => (
                    <TableRow key={playlist.sys.id}>
                        <TableCell>
                            <Checkbox onClick={handleCheckboxChange} />
                        </TableCell>
                        <TableCell>
                          {playlist.fields?.creator['en-US']}
                        </TableCell>
                        <TableCell>
                          <TextLink target="blank" href={`https://apmmusic.com/playlists/${playlist.fields.playlist_id['en-US']}`}>
                            {playlist.fields?.title['en-US']}
                          </TextLink>
                        </TableCell>
                        <TableCell>
                          <DateTime date={playlist.fields.created['en-US']}/>
                        </TableCell>
                        <TableCell>
                          {playlist.fields.publish_date['en-US'] && <DateTime date={playlist.fields.publish_date['en-US']} />}
                        </TableCell>
                        <TableCell>
                          <TextLink onClick={() => handleEditClick(playlist)}>
                            Edit
                          </TextLink>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

PlaylistTable.defaultProps = {
    contents: [{
        creatorName: '',
        title: '',
        createdAt: '',
        updatedAt: ''
    }],
    onItemSelected: () => {},
    onItemEdit: () => {},
};

export default PlaylistTable;
