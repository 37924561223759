import React from 'react';
import { Icon, Workbench } from '@contentful/forma-36-react-components';
import PlaylistTable from './PlaylistTable';
import Pagination from './Pagination';

const Page = (props) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [playlists, setPlaylists] = React.useState([]);
  const [offset, setOffset] = React.useState(20);
  const [total, setTotal] = React.useState(0);

  const handlePaginationClick = (page) => {
    setCurrentPage(page);
  }

  const handlePlaylistEdit = (playlist) => {
    props.sdk.navigator.openEntry(playlist.sys.id);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const storedPlaylists = await props.sdk.space.getEntries({
        content_type: 'playlist',
        limit: offset,
      });

      setTotal(storedPlaylists.total);
      setPlaylists(storedPlaylists.items);
    }

    fetchData();
  }, [props.sdk.space, offset])

  React.useEffect(() => {
    const fetchData = async (page) => {
      const storedPlaylists = await props.sdk.space.getEntries({
        content_type: 'playlist',
        skip: (page-1) * offset,
        limit: offset,
      });

      setTotal(storedPlaylists.total);
      setPlaylists(storedPlaylists.items);
    }
    fetchData(currentPage);
  }, [currentPage, props.sdk.space, offset])

  return (
    <Workbench>
      <Workbench.Header title={"Manage Playlists"} description={"Search and manipulate playlists"} icon={<Icon icon="Star" color="warning" />}/>
      <Workbench.Content type="default">
        <PlaylistTable contents={playlists} onItemEdit={handlePlaylistEdit}/>
        <Pagination onClick={handlePaginationClick} total={Math.round(total/offset)} current={currentPage} />
      </Workbench.Content>
    </Workbench>
  )
};

export default Page;
